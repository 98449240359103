.c-symbol {
  display: inline-flex;align-items: center;
  &-cursor{cursor: pointer}
  &-icon {
    display: block;
  }

  &-size-default &-icon {
    width: 28px;height: 28px;
    + * {
      margin-left: 10px;
    }
  }
  &-size-small &-icon {
    width: 24px;height: 24px;
    + * {
      margin-left: 8px;
    }
  }
  &-size-mini &-icon {
    width: 20px;height: 20px;
    + * {
      margin-left: 8px;
    }
  }
  &-size-mini &-inner {
    font-size: 13px;color: $text2;
  }
  &-price {
    display: inline-flex;align-items: center;
    &.bold {
      font-weight: bold;
    }
    &.fall {
      color: $fall;
    }
  }
  &-name {
    color: $text2;font-size: 13px;
  }
  &-full {}
  &-full &-name {}
  &-full &-multiple {
    display: flex;align-items: center;height: 16px;margin-top: 2px;font-size: 13px;
    span {
      color: $text3;margin-right: 8px;
    }
    em {
      text-transform:capitalize;
      &.long {
        color: $green;
      }
      &.short {
        color: $red;
      }
    }
  }
  &-fullname {
    display: flex;align-items: center;

    .c-tag {
      margin-left: 5px;
    }
  }

  &-abbr-usd {
    font-size: 11px;color: $text3;
  }
}

.mobile-mode {
  .c-symbol {
    &-size-default .c-symbol-icon {
      width: rem(28);height: rem(28);
      + * {
        margin-left: rem(10);
      }
    }
    &-size-small .c-symbol-icon {
      width: rem(24);height: rem(24);
      + * {
        margin-left: rem(8);
      }
    }
    &-size-mini .c-symbol-icon {
      width: rem(20);height: rem(20);
      + * {
        margin-left: rem(8);
      }
    }
    &-size-mini .c-symbol-inner {
      font-size: rem(13);
    }
    &-name {
      font-size: rem(13);
    }
    &-full .c-symbol-multiple {
      height: rem(16);margin-top: rem(2);font-size: rem(13);
      span {
        margin-right: rem(8);
      }
    }
    &-fullname {
      .c-tag {
        margin-left: rem(5);
      }
    }

    &-abbr-usd {
      font-size: rem(11);
    }
  }
}
