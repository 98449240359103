.web-form-input {
  display: flex;align-items: flex-start;
  input, textarea {
    width: 100%;display: block;
    background: $cff;
    border: 2px solid $lBlue;
    border-radius: 8px;
    color: $blue;
    &:focus {
      border-color: $blue;
      color: $blue;
      box-shadow: 0px 0px 16px #98C3FF;
    }
    &::placeholder {
      color: #D1E0FE;
    }
    &:disabled {
      background: $bgBlue;border-color: $bgBlue;color: $text;
    }
  }
  input {
    height: 72px;padding: 0 24px;font-size: 24px;font-weight: bold;
  }
  textarea {
    height: 208px;padding: 24px;font-size: 24px;line-height: 1.5;
  }
  &-suffix {
    font-size: 24px;line-height: 24px;color: $lBlue;padding-left: 16px;font-weight: bold;height: 72px;
    display: flex;align-items: center;white-space: nowrap;
  }
  &.right {
    input {
      text-align: right;
    }
  }
  &-base {
    display: flex;align-items: center;flex-flow: column;width: 100%;
    & > label {
      width: 100%;display: block;font-size: 12px;line-height: 16px;
      letter-spacing: 4px;font-weight: 800;text-transform: uppercase;
      color: $text;margin-bottom: 8px;
    }
  }
  &-count {
    width: 100%;text-align: right;font-size: 16px;line-height: 24px;color: $text;margin-top: 8px;
  }
  &-more {
    display: flex;justify-content: space-between;width: 100%;margin-top: 8px;
  }
  &-set-max-btn span {
    display: block;color: $blue;font-weight: bold;text-transform: uppercase;letter-spacing: 4px;cursor: pointer;position: relative;
    &:after {
      content: '';display: block;left: 50%;right: 50%;bottom: 0;height: 1px;background: $blue;position: absolute;
      @include dz();
    }
    &:hover {
      &:after {
        left: 0;right: 4px;
      }
    }
  }
  &-inner {
    font-size: 16px;color: $text;line-height: 24px;
  }
}
