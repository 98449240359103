// 黑白灰
$c55: #555;
$cff: #fff;
$c00: #000;
$c66: #666;
$c22: #222;
$c33: #333;
$c44: #444;
$c88: #888;
$cf7: #f7f7f7;

$cf8: #f8f8f8;
$ce7: #e7e7e7;
$cc4: #c4c4c4;
$c9b: #9b9b9b;
$c73: #737373;
$c26: #2C2626;

$white: $cff;
$bg: $cf8;
$line: $ce7;
$gray: $cc4;
$dGray: $c9b;
$text: $c73;
$black: $c00;

// system
$succ: #4ADCB3;
$fail: #FD3D60;
$wait: #FFB764;
$info: #B1DCFF;

$rise: $succ;
$fall: $fail;

// 彩色
// 蓝色
$blue: #1036E6;
// 浅蓝色
$lBlue: #467AFF;
// 作为背景用的蓝色
$bgBlue: #F1F3F9;
// 橘色
$orange: #FEA036;
// 浅橘色
$lOrange: #FFB663;
// 深橘色
$dOrange: #D55C05;
// 绿色
$green: #007955;
$lGreen: #4ADCB3;
// 紫色
$purple: #632ECA;
$red: $fail;

$bgRed: #FFECEC;


// 主色
$primary: $blue;
// 线条
$line: $cf7;
// 背景
