.c-order-success-notify {
  & > header {
    display: flex;justify-content: space-between;margin-bottom: 10px;
    label {
      display: flex;align-items: center;
      img {
        display: block;width: 20px;height: 20px;margin-right: 8px;
      }
      strong {
        font-size: 15px;font-weight: normal;color: $text2;
      }
    }
    span {
      font-size: 12px;color: $text3;
      display: flex;align-items: center;

      &:after {
        content: '';display: block;width: 16px;height: 16px;border-radius: 50%;margin-left: 10px;
        box-shadow: 0 0 0 1px $green inset, 0 0 0 3px $bg inset;
        background: $green;
      }
    }
  }
  & > section {
    margin-bottom: -7px;
  }
}
