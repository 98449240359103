.web-form-upload {
  display: flex;align-items: center;justify-content: center;width: 120px;height: 120px;
  border: 2px dashed $ce7;border-radius: 24px;text-align: center;cursor: pointer;
  background: $cff;
  label {cursor: pointer;
    display: flex;align-items: center;justify-content: center;
    width: 100px;height: 100px;overflow: hidden;border-radius: 16px;
    input {
      display: none;
    }
    i {
      font-size: 10px;line-height: 1.4;
    }
    img {
      max-width: 100px; max-height: 100px;
    }
  }
}

.dark, .mobile-mode.dark {
  .web-form-upload {
    border-color: $c44;
    background: $c22;
  }
}
