.c-tag {
  border: none;cursor: pointer;
  @include dz();position: relative;
  display: inline-flex;justify-content: center;align-items: center;
  text-transform: capitalize;
  &-type {
    &-default {
      background: $dark1;color: $text1;
      &:hover, &:focus {
        background: lighten($dark1, 5%);
      }
    }
    &-blue {
      background: $blue;color: $text1;
      &:hover, &:focus {
        background: lighten($blue, 5%);
      }
    }
    &-green {
      background: $green;color: $text1;
      &:hover, &:focus {
        background: lighten($green, 5%);
      }
    }
    &-yellow {
      background: $yellow;color: $text1;
      &:hover, &:focus {
        background: lighten($yellow, 5%);
      }
    }
    &-red {
      background: $red;color: $text1;
      &:hover, &:focus {
        background: lighten($red, 5%);
      }
    }
    &-color {
      background: linear-gradient(98.02deg, #FFE9E9 2.99%, #7EDDF4 55.93%, #9EF1E0 113.13%);color: $dark4;
    }
    &-long {
      color: $green;
      background: rgba($green, .2);
    }
    &-short {
      color: $red;
      background: rgba($red, .2);
    }
  }

  &-size {
    &-default {
      min-height: 32px;padding: 8px 16px;font-size: 13px;border-radius: 6px;
      &.round {
        border-radius: 16px; padding: 0 20px;
      }
    }
    &-small {
      min-height: 24px;padding: 2px 6px;font-size: 12px;border-radius: 4px;font-weight: normal;
      &.round {
        border-radius: 12px;padding: 0 12px;
      }
    }
    &-mini {
      min-height: 16px;padding: 2px 4px;font-size: 10px;border-radius: 3px;font-weight: normal;
      &.round {
        border-radius: 8px;padding: 0 8px;
      }
    }
  }

  &.bold {
    font-weight: bold;
  }
}

.mobile-mode {
  .c-tag {
    &-size {
      &-default {
        min-height: rem(32);padding: rem(8) rem(16);font-size: rem(13);border-radius: rem(6);
        &.round {
          border-radius: rem(16); padding: 0 rem(20);
        }
      }
      &-small {
        min-height: rem(24);padding: rem(3) rem(6);font-size: rem(12);border-radius: rem(4);
        &.round {
          border-radius: rem(12);padding: 0 rem(12);
        }
      }
      &-mini {
        min-height: rem(16);padding: rem(2) rem(4);font-size: rem(10);border-radius: rem(3);
        &.round {
          border-radius: rem(8);padding: 0 rem(8);
        }
      }
    }
  }
}
