.web-footer {
  height: 36px;
  // position: absolute;bottom: 0;left: 0;right: 0;
  display: flex;align-items: center;border-top: 1px solid $line;
  justify-content: space-between;padding: 0 20px;
  @include ns();
  main {
    display: flex;align-items: center;

    .status {
      display: flex;align-items: center;
      font-size: 13px;color: $text3;

      &:before, &:after {
        display: block;content: ''
      }

      &:before {
        width: 6px;height: 6px;border-radius: 50%;margin-right: 8px;
      }

      &:after {
        width: 13px;height: 13px;margin-left: 4px;opacity: .4;
        background: url($res + 'icon/link-arrow.svg') center/contain no-repeat;
        @include dz();
      }

      &:hover {
        color: $text1;
        &:after {
          opacity: 1;
        }
      }

      &.connect{
        &:before {
          background: $green
        }
      }

      &.disconnect{
        &:before {
          background: $red
        }
      }

    }

    span {
      font-size: 12px;color: $text3;margin-left: 40px;display: block;
    }

    hr {
      width: 1px;height: 16px;border: none;
      background: $dark1;margin: 0 16px;
    }
  }

  &-links {
    display: flex;align-items: center;
    a {
      display: flex;align-items: center;margin-left: 40px;font-size: 12px;color: $text3;

      img {
        display: block;width: 14px;height: 14px;margin-right: 10px;
      }

      &:after {
        display: block;content: '';
        width: 13px;height: 13px;margin-left: 4px;opacity: .4;
        background: url($res + 'icon/link-arrow.svg') center/contain no-repeat;
        @include dz();
      }

      &:hover {
        color: $text1;
        &:after {
          opacity: 1;
        }
      }
    }
  }
}
