.web-header {
  @extend .web_;padding: 0 8px;height: 48px;
  display: flex;align-items: center;justify-content: space-between;
  border-bottom: 1px solid $line;
  &-logo {
    width: 120px;
    a {
      text-indent: -9999px;height: 30px;display: block;
      background: url($res + 'website/logo.svg') center left /contain no-repeat;
    }
  }
  &-tools, &-main, &-nav {
    display: flex;align-items: center;
  }
  &-line {
    height: 24px;width: 1px;border: none;background: $line;margin: 0 10px;
  }
  &-bar-icon {
    width: 24px;height: 24px;display: block;cursor: pointer;
  }
  &-nav {
    padding: 0 12px;
    & > a {
      display: flex;align-items: center;justify-content: center;
      padding: 0 12px;margin: 0 12px;border-radius: 8px;height: 32px;
      background: $bg;font-size: 14px;color: $text3;
      &:hover {
        background: $dark1;color: $text1;
      }
      &.active {
        background: $dark4;color: $text1;
      }
    }

    .c-dropdown {
      &-label {
        color: $text3;border-radius: 8px;font-size: 14px;
      }
      &.show .c-dropdown-label {
        color: $text1;background: $dark1;
      }
    }

  }

  .unsupported {button{color:$wait;}}
}
