.c-react-modal {
  &-overlay {
    position: fixed;top: 0;right: 0;left: 0;bottom: 0;z-index:20;
    background: rgba($black, .5);opacity: 0;@include dz();
    @include mh(5px);
  }
  &-content {
    position: absolute;left: 50%;top: 50%;
    @include yd(-50%, -50%);padding: 20px 24px;background: $dark3;
    border-radius: 16px;

    &:focus-visible {
      outline: none;
    }

    .c-input-number-label label {
      color: $text3;
    }
  }
  &-noscroll {
    overflow-y: hidden;
  }
  &-header {
    display: flex;align-items: center;justify-content: space-between;height: 24px;
    margin-bottom: 16px;
    h4 {
      font-size: 18px;font-weight: bold;color: $text2;
    }

    button {
      display: block;border: none;width: 32px;height: 32px;margin-right: -6px;
      background: url($res + 'icon/close.svg') center/contain no-repeat;cursor: pointer;
      @include dz();border-radius: 50%;
      &:hover {
        @include xz(180);
      }
    }
  }

  &-body {
    font-size: 13px;color: $text3;line-height: 20px;
    max-height: 70vh;overflow-y: auto;
  }
  &-footer {
    margin-top: 16px;display: flex;justify-content: flex-end;

    & > * + * {
      margin-left: 10px;
    }
  }
}

.ReactModal__Overlay--after-open{
  opacity: 1;
}

.ReactModal__Overlay--before-close{
  opacity: 0;
}

.mobile-mode {
  .c-react-modal {
    &-content {
      padding: rem(20) rem(24);
      border-radius: rem(16);
    }

    &-header {
      height: rem(24);
      margin-bottom: rem(16);
      h4 {
        font-size: rem(18);
      }

      button {
        width: rem(32);height: rem(32);margin-right: rem(-6);
      }
    }

    &-body {
      font-size: rem(13);line-height: rem(20);margin: 0 rem(-10);padding: 0 rem(10);

    }
    &-footer {
      margin-top: rem(16);

      & > * + * {
        margin-left: rem(10);
      }
    }
  }
}

.m-bottom-modal {
  &-content {
    position: absolute;bottom: 0;left: 0;right: 0;background: $dark3;padding: 0 rem(20) rem(20);
    border-radius: rem(16) rem(16) 0 0;
    box-shadow: 0px 0px 14px -4px rgba(0, 0, 0, 0.05), 0px 32px 48px -8px rgba(0, 0, 0, 0.1);
  }
  &-noscroll {
    overflow-y: hidden;
  }
  &-header {
    display: flex;justify-content: center;
    button {
      height: rem(43);width: rem(43);border: none;background: none;
      &:after {
        content: '';display: block;height: rem(3);border-radius: rem(1.5);background: $text3;
      }
    }
  }
  &-body {
    max-height: 70vh; overflow-y: auto;
  }
}
