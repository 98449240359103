.c-marquee {
  width: 100%;display: flex;align-items: center;
  &-area {
    flex: 1;overflow: hidden;
  }
  &-inner {
    white-space: nowrap;display: block;position: relative;
    @include dz();
    & > * {
      display: inline-flex;
    }
  }
  &-arrow {
    width: 24px;height: 24px;cursor: pointer;
    background: url($res + 'icon/arrow-down.svg') center/50% auto no-repeat;
    &-left {
      @include xz(90);
    }
    &-right {
      @include xz(-90);
    }
  }
}
