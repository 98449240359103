@keyframes load{
  0% { opacity: 1; }
  100% { opacity: 0.2; }
}
@keyframes wait-modal {
  0% { left: -50px; width: 50px;}
  50% { left: calc(50% - 100px); width: 200px;}
  100% { left: 100%; width: 50px;}
}

@keyframes loading {
  0% { transform: scale(1) }
  100% { transform: scale(1.5) }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
