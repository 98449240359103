.c-no-data {
  display: flex;flex-flow: column;align-items: center;
  font-size: 14px;color: $text3;
  &:before {
    content: '';display: block;width: 98px;height: 98px;
    background: url($res + 'icon/no-data.svg') center/contain no-repeat;
  }
}

.mobile-mode {
  .c-no-data {
    font-size: rem(14);
    &:before {
      width: rem(98);height: rem(98);
    }
  }
}
