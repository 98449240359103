$s: 20px;
// $colors: #1FBCD3, #CBE2B4, #F6B6CA;
$colors: $text3, $text3, $text3;

@mixin cbox{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
@mixin fbox{
  @include cbox;
  width: 100%;
  height: 100%;
}

.c-loading{
  @include cbox;
  transform-style: preserve-3d;
  perspective: 2000px;
  transform: rotateX(-30deg) rotateY(-45deg) translate(-50%, -50%);

  &-layout {
    position: relative;height: 100px;
  }
}
.c-loading-holder{
  @include cbox;
  transform-style: preserve-3d;
  transform: translate3d(0em, $s, $s*.5);
  &:last-child{
    transform: rotateY(-90deg) rotateX(90deg) translate3d(0, $s, $s*.5);
  }
  &:first-child{
    transform: rotateZ(-90deg) rotateX(-90deg) translate3d(0, $s, $s*.5);
  }
  @for $i from 1 through 3{
    $color: nth($colors,$i);
    &:nth-child(#{$i}){
      .c-loading-box{
        background-color: $color;
        &:before{
          background-color: darken($color,20);
        }
        &:after{
          background-color: darken($color,10);
        }
      }
    }
  }
}
.c-loading-box{
  @include cbox;
  transform-style: preserve-3d;
  animation: ani-box 6s infinite;
  width: $s;
  height: $s;
  //opacity: .9;
  &:before, &:after{
    position: absolute;
    width: 100%;
    height: 100%;
    content: "";
  }
  &:before{
    left: 100%;
    bottom: 0;
    transform: rotateY(90deg);
    transform-origin: 0 50%;
  }
  &:after{
    left: 0;
    bottom: 100%;
    transform: rotateX(90deg);
    transform-origin: 0 100%;
  }
}

@keyframes ani-box{
  8.33%{
    transform: translate3d(-50%,-50%,0) scaleZ(2);
  }
  16.7%{
    transform: translate3d(-50%,-50%,-$s) scaleZ(1);
  }
  25%{
    transform: translate3d(-50%,-100%,-$s) scaleY(2);
  }
  33.3%{
    transform: translate3d(-50%,-150%,-$s) scaleY(1);
  }
  41.7%{
    transform: translate3d(-100%,-150%,-$s) scaleX(2);
  }
  50%{
    transform: translate3d(-150%,-150%,-$s) scaleX(1);
  }
  58.3%{
    transform: translate3d(-150%,-150%, 0) scaleZ(2);
  }
  66.7%{
    transform: translate3d(-150%,-150%,0) scaleZ(1);
  }
  75%{
    transform: translate3d(-150%,-100%,0) scaleY(2);
  }
  83.3%{
    transform: translate3d(-150%,-50%,0) scaleY(1);
  }
  91.7%{
    transform: translate3d(-100%,-50%,0) scaleX(2);
  }
  100%{
    transform: translate3d(-50%,-50%,0) scaleX(1);
  }
}

.mobile-mode {
  $mobile-size: rem(20);
  $half-mobile-size: rem(10);

  .c-loading{
    &-layout {
      height: rem(100);
    }
  }
  .c-loading-holder{
    transform: translate3d(0em, $mobile-size, $half-mobile-size);
    &:last-child{
      transform: rotateY(-90deg) rotateX(90deg) translate3d(0, $mobile-size, $half-mobile-size);
    }
    &:first-child{
      transform: rotateZ(-90deg) rotateX(-90deg) translate3d(0, $mobile-size, $half-mobile-size);
    }
  }
  .c-loading-box{
    animation: mobile-ani-box 6s infinite;
    width: $mobile-size;
    height: $mobile-size;
  }

  @keyframes mobile-ani-box{
    8.33%{
      transform: translate3d(-50%,-50%,0) scaleZ(2);
    }
    16.7%{
      transform: translate3d(-50%,-50%,-$mobile-size) scaleZ(1);
    }
    25%{
      transform: translate3d(-50%,-100%,-$mobile-size) scaleY(2);
    }
    33.3%{
      transform: translate3d(-50%,-150%,-$mobile-size) scaleY(1);
    }
    41.7%{
      transform: translate3d(-100%,-150%,-$mobile-size) scaleX(2);
    }
    50%{
      transform: translate3d(-150%,-150%,-$mobile-size) scaleX(1);
    }
    58.3%{
      transform: translate3d(-150%,-150%, 0) scaleZ(2);
    }
    66.7%{
      transform: translate3d(-150%,-150%,0) scaleZ(1);
    }
    75%{
      transform: translate3d(-150%,-100%,0) scaleY(2);
    }
    83.3%{
      transform: translate3d(-150%,-50%,0) scaleY(1);
    }
    91.7%{
      transform: translate3d(-100%,-50%,0) scaleX(2);
    }
    100%{
      transform: translate3d(-50%,-50%,0) scaleX(1);
    }
  }
}
