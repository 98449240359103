.web-c-time-select {
  background: $lBlue;border-radius: 5px;padding: 2px;
  display: flex;align-items: center;
  span {
    display: flex;min-width: 80px;height: 28px;color: $white;cursor: pointer;
    @include dz();align-items: center;justify-content: center;border-radius: 4px;
    font-size: 10px;font-weight: 800;letter-spacing: 2px;text-transform: uppercase;
    padding: 0 16px;
    &:not(:last-child) {
      margin-right: 2px;
    }
    &:hover {
      background: rgba($white, .15);
    }
    &.active {
      &,&:hover {
        background: $white;color: $blue;
      }
    }
  }
}
