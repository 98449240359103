.web-balance-show {
  //text-transform: uppercase;
  strong {
    font-size: 1.54em;
  }
  small {
    font-size: 1em;
  }
  span {
    font-size: 1em;margin-left: 8px;
  }
  &.million, &.dec {
    small {
      font-weight: bold;font-size: 1.54em;text-transform: uppercase;
    }
  }
}
