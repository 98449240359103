.web-c-slider {
  height: 32px;width: 100%;
  &-layout {
    position: relative;height: 60px;
  }
  &-track {
    height: 32px;display: flex;align-items: center;position: relative;
    &:after {
      content: '';display: block;width: 100%;
    }
    &-0 {
      &:after {
        height: 4px;border-radius: 2px;
        background: $lOrange;
      }
    }
    &-1:after {
      height: 2px;background: $bgBlue;border-radius: 1px;
    }
  }
  &-thumb {
    width: 32px;height: 32px;border-radius: 10px;cursor: pointer;
    @include jb($lOrange, $orange, 135);box-shadow: 0px 0px 16px $orange;
    display: flex;justify-content: center;align-items: center;outline: none;
    &:before {
      content: '';display: block;width: 15px;height: 15px;
      background: url($res + 'icon/slider-heart.svg') center/contain no-repeat;
    }
  }
  &-marks {
    display: flex;justify-content: space-between;position: absolute;top: 0;left: 0;right: 0;height: 32px;align-items: center;
    pointer-events: none;
    span {
      display: block;width: 12px;height: 12px;border-radius: 50%;background: $lBlue;position: relative;cursor: pointer;
      &.active {
        background: $orange;box-shadow: 0px 0px 4px $orange;
      }
      &:after {
        content: '0%';display: block;position: absolute;color: $dGray;
        font-size: 16px;line-height: 24px;top: 28px;
      }
      &:nth-child(1):after {content: '0%';}
      &:nth-child(2):after {content: '25%';}
      &:nth-child(3):after {content: '50%';}
      &:nth-child(4):after {content: '75%';}
      &:nth-child(5):after {content: '100%';}
      &:nth-child(2), &:nth-child(3), &:nth-child(4) {
        &:after {
          left: 50%;@include yd(-50%, 0);
        }
      }
      &:first-child:after {
        left: 0;
      }
      &:last-child:after {
        right: 0;
      }
    }
  }
}
