.web-c-alert {
  width: 100%;height: 52px;padding: 0 42px;
  display: flex;align-items: center;border-radius: 8px;

  &:before {
    content: '';display: block;width: 32px;height: 32px;
    background: center/contain no-repeat;margin-right: 18px;
  }

  &-error {
    background: #FFECEC;color: $red;
    &:before {
      background-image: url($res + 'icon/warning.svg');
    }
  }
}
