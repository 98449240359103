.c-progress {
  header {
    display: flex;justify-content: space-between;font-size: 12px;
  }
  &-line {
    background: rgba($text1, .2);height: 6px;border-radius: 3px;margin: 8px 0;
    hr {
      margin: 0;height: 6px;border-radius: 3px;background: $blue;border: none;
    }
  }
  footer {
    text-align: right;color: $blue;font-size: 12px;font-weight: bold;
  }
}
