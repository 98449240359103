
.Toastify {
  &__toast {
    &-icon {
      width: 24px;height: 24px;
      background: center/contain no-repeat;
      svg {
        display: none;
      }
    }
    &--info &-icon {
      background-image: url($res + 'icon/info.svg');
    }
    &--success &-icon {
      background-image: url($res + 'icon/success.svg');
    }
    &--warning &-icon {
      background-image: url($res + 'icon/warning.svg');
    }
    &--error &-icon {
      background-image: url($res + 'icon/error.svg');
    }
    &-body {
      color: $text1;
    }
    &-theme--dark {
      background: $dark2;
    }

    &-container--top-right &-body {
      align-items: flex-start;
    }
  }
  &__close-button {
    @include dz();
    &:hover {
      @include xz(180);
    }
  }
  &__progress-bar {
    &--info {
      background: $blue;
    }
    &--success {
      background: $green;
    }
    &--warning {
      background: $yellow;
    }
    &--error {
      background: $red;
    }
  }
}

.c-notify {
  &-title {
    font-size: 15px;line-height: 24px;font-weight: bold;color: $text2;
  }
  &-content {
    font-size: 13px;line-height: 22px;color: $text3;
    span, strong, em {
      color: $text2;
    }
  }
  &-custom {
    border-radius: 12px;
    border: 2px solid $line;
    position: relative;
    overflow: inherit;
    background: $dark2;
    .Toastify__close-button {
      position: absolute;width: 20px;height: 20px;border-radius: 50%;background: $line;
      right: -10px;top: -10px;display: flex;justify-content: center;align-items: center;
    }
    .Toastify__progress-bar {
      border-radius: 0 0 12px 12px;height: 3px;left: 2px;right: 2px;
    }
  }
}

.mobile-mode {
  .Toastify {
    &__toast {
      margin-bottom: rem(10);border-radius: rem(4);opacity: .98;
      &-icon {
        width: rem(24);height: rem(24);
      }
      &-container {
        padding: rem(16);
      }
      &-body {
      }
    }
  }
  .c-notify {
    &-title {
      font-size: rem(16);line-height: rem(24);
    }
    &-content {
      font-size: rem(14);line-height: rem(22);
    }
    &-custom {
      border-radius: rem(12);
      .Toastify__close-button {
        width: rem(20);height: rem(20);
        right: rem(-10);top: rem(-10);
      }
      .Toastify__progress-bar {
        border-radius: 0 0 rem(12) rem(12);height: rem(3);left: rem(2);right: rem(2);
      }
    }
  }

}
// @media only screen and (max-width: 480px) {
//   .Toastify__toast
// }
