.c-input-number {
  width: 100%;
  &-label {
    display: flex;justify-content: space-between;height: 16px;margin-bottom: 8px;align-items: center;font-size: 12px;
    label {
      display: flex;align-items: center;padding-left: 8px;
      color: $text2;
      .c-tag {
        margin-left: 10px;
      }
    }
    p {
      color: $red;
    }
    &-right {
      color: $text3;
      span, em, u, i {
        color: $text2;
      }
    }
    &-value{
      color: $text2;padding-left: 5px;
    }
  }
  &-inner {
    position: relative;background: $dark2;border-radius: 6px;height: 40px;padding: 8px 12px;
    @include dz();
    &:hover {
      background: lighten($dark2, 2%);
    }
  }
  &.error &-inner {
    box-shadow: 0 0 0 1px $red inset;
  }
  &.focus &-inner {
    box-shadow: 0 0 0 1px darken($blue, 20%) inset;
  }
  &.readonly &-inner {
    background: $dark3;
  }
  &.readonly.focus &-inner {
    box-shadow: none;
  }
  &-numeric {
    border: none;background: none;color: $text2;font-size: 16px;font-weight: bold;height: 24px;letter-spacing: 1px;
    width: 100%;
    &::placeholder {
      color: $text3;
    }
  }
  &-buttons {
    position: absolute;right: 8px;top: 8px;
    & > * {
      margin-left: 4px;
    }
  }
  &-data {
    margin-top: -8px;padding: 20px 12px 12px;background: $dark3;border-radius: 6px;
    &-item {
      display: flex;align-items: center;justify-content: space-between;height: 16px;font-size: 12px;
      label {
        color: $text3;display: flex;align-items: center;
        .c-tag {
          margin-left: 5px;text-transform: uppercase;
        }
      }
      span {
        color: $text2;text-transform: uppercase;
      }
      &:not(:first-child) {
        margin-top: 12px;
      }
    }
  }
  &-extra-button {
    position: absolute;right: 8px;top: 32px;
  }
}

.mobile-mode {
  .c-input-number {
    &-label {
      height: rem(16);margin-bottom: rem(8);font-size: rem(14);
      label {
        padding-left: rem(8);
        .c-tag {
          margin-left: rem(10);
        }
      }
      &-value{
        padding-left: rem(5);
      }
    }
    &-inner {
      border-radius: rem(6);height: rem(40);padding: rem(8) rem(12);
    }
    &-numeric {
      font-size: rem(16);height: rem(24);letter-spacing: rem(1);
    }
    &-buttons {
      right: rem(8);top: rem(8);
      & > * {
        margin-left: rem(4);
      }
    }
    &-data {
      margin-top: rem(-8);padding: rem(20) rem(12) rem(12);border-radius: rem(6);
      &-item {
        height: rem(16);font-size: rem(14);
        label {
          .c-tag {
            margin-left: rem(5);
          }
        }
        &:not(:first-child) {
          margin-top: rem(12);
        }
      }
    }
    &-extra-button {
      right: rem(8);top: rem(32);
    }
  }
}
