.web-c-percentage-line {
  width: 100%;background: $bgBlue;
  overflow: hidden;
  &-inner {
    height: 10px;
    @include jb($lOrange, $orange, 135);
    @include dz();
  }
  &.mini {
    height: 10px;border-radius: 5px;
    .web-c-percentage-line-inner {
      height: 10px;
    }
  }
  &.large {
    height: 16px;border-radius: 8px;
    .web-c-percentage-line-inner {
      height: 16px;
    }
  }
}
