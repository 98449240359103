.c-table-select {
  display: table;width: 100%;background: $bg;padding: 4px;height: 40px;border-radius: 6px;
  @include ns();
  span {
    display: table-cell;
    text-align: center;line-height: 36px;color: $text3;cursor: pointer;
    &.active {
      background: $dark1;border-radius: 4px;color: $text2;font-weight: bold;
    }
  }
}

.mobile-mode {
  .c-table-select {
    padding: rem(4);height: rem(40);border-radius: rem(6);
    span {
      line-height: rem(36);
      &.active {
        border-radius: rem(4);
      }
    }
  }
}
