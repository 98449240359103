.c-tip {
  font-size: 12px;line-height: 20px;color: $text2;
  &.icon {
    padding-left: 22px;position: relative;
    &:before {
      content: '';display: block;width: 16px;height: 16px;
      position: absolute;left: 0;top: 2px;
      background: center/contain no-repeat;
    }
  }
  &.warning {
    color: $yellow;
    &:before {
      background-image: url($res + 'icon/warning.svg');
    }
  }
}
