.web-status {
  &-fail, &-wait, &-success, &-info {
    display: flex;flex-flow: column;align-items: center;
    img {
      display: block;
    }
    h4 {
      font-family: 'Didot';font-size: 32px;line-height: 40px;color: $blue;font-weight: bold;
      margin-top: 32px;
    }
    p {
      font-size: 12px;line-height: 1.6;width: 440px;text-align: center;margin-top: 12px;color: $text;
    }
    .web-button {
      margin-top: 32px;width: 180px;
    }
  }
  &-wait {
    img {
      width: 208px;height: 136px;
    }
    h4 {
      margin-bottom: 32px;
    }
    span {
      display: block;position: relative;height: 10px;border-radius: 5px;background: $bgBlue;width: 440px;overflow: hidden;
      &:after {
        content: '';display: block;position: absolute;height: 10px;border-radius: 5px;
        left: 0;bottom: 0;
        @include jb($orange, $lOrange, -135);
        animation:wait-modal 2s infinite;
      }
    }
  }
  &-fail {
    img {
      width: 252px;height: 178px;
    }
  }
  &-success {
    img {
      width: 174px;height: 160px;
    }
  }
  &-info {
    footer {
      .web-button {
        margin: 32px 10px 0;
      }
    }
  }
}
